.notification-banner {
  margin-top: 4em;
}

.notification-banner .image-container {
  justify-content: center;
  padding: 3em 0 3em 0;
}

.notification-banner .message {
  text-align: center;
  font-size: 1.5em;
  padding: 1em 1em 0 1em;
}

.notification-banner .timer {
  text-align: center;
}
