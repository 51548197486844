.banner {
  position: absolute;
  margin: 3%;
}

.banner .banner-text {
  margin-top: 15%;
}

.banner .banner-heading {
  font-size: 3.7rem;
  color: white;
  padding: 15px 30px;
  line-height: 69px;
  width: 80%;
}

.banner .banner-sub-heading {
  font-size: 1.3rem;
  color: white;
  padding: 29px;
  margin-right: 20px;
  width: 80%;
}

.banner .blinker {
  animation: blinker 1s cubic-bezier(0.54, 1.49, 1, 1) infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.lang-svg-transition {
  transition: transform 1s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.position-absolute {
  position: absolute;
}

.banner .login-logo {
  float: left;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.free-trial-modal .ant-modal-body{
  padding: 0.5vw 0.5vw !important;
  /* border-radius: 20%; */
}

.free-trial-modal .ant-modal-content{
  border-radius: 1.5%;
}
