free-org-admin-form {
  /* max-height: 70vh;
    overflow: scroll; */
}

.free-org-admin-form .ant-form-item-control-input-content>label {
  position: absolute;
  top: -1.5vh;
  left: 1.8vw;
  z-index: 1;
  background-color: white;
  padding: 0px 5px;
  color: #424242;
  font-size: 1vw;
  font-weight: 600;
}

.free-org-admin-form .ant-modal-content {
  border-radius: 10px;
  width: 38vw;
}

.free-org-admin-form .ant-input {
  /* height: 7vh; */
}

.free-org-admin-form .ant-btn {
  /* height: 7vh; */
  font-weight: 900;
}

.free-org-admin-form .ant-form-item {
  margin-bottom: 5vh;
}

.free-org-admin-form .ant-modal-body {
  padding: 0 24px;
}

.free-org-admin-form .tNc {
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.ant-form-item {
  margin-bottom: 1.4vh;
}

.submit-button {
  /* font-size: 1.2vh; */
  width: 100%;
height:40px;
padding: 8px;
border-radius: 4px;
border: 0.5px;
gap: 8px;
display: flex;
align-items: center;
justify-content: center;
}




.free-org-admin-form-new{
  width: 600px;
  /* height: 645px; */
  padding: 2rem;
  background: #FFFFFF;
  border-radius: 0.3rem;
}
.signup-card-header{
  width: 100%;
  /* height: 72px; */
 margin-bottom: 0.5rem;
 display: flex;
 flex-direction: column;
 align-items:center;
 justify-content: center;
}
.signup-card-header-text{
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: center;
  
}
.signup-card-header-sub{
  width: 100%;
  /* height: 42px; */
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
.form-main-container{
  width:100%;
  /* height: 304px; */

}
.form-box-item{
  width: 100%;
  height: 48px;
  /* padding: 8px 16px 8px 16px; */
  border-radius: 4px;
  border: 0.5px;
  border: 0.5px solid #DADCE0;
}

.login-link-box{
width: 100%;
height:40px;
padding: 5px;
border-radius: 4px;
border: 0.5px;
border: 0.5px solid #DADCE0;
display: flex;
align-items: center;
justify-content: center;

}

.otp-button{
  color: white;
  width: 100%;
  font-size: 10px;
  height: 48px;
}

@media (min-width:300px) and (max-width:480px){
  .free-org-admin-form .ant-form-item-control-input-content>label {
    font-size: 3vw;
  }

  .ant-form-item{
   margin-bottom: 2vh;
  }
  .free-org-admin-form .tNc {
    font-size: 0.8rem;
  }

  .free-org-admin-form-new{
    width: 600px;
    height: 560px;
    padding: 1rem;
    background: #FFFFFF;
  }
  .signup-card-header{
    width: 100%;
    height: 82px;
    margin-bottom: 1rem;
  }
  .signup-card-header-text{
    color: #000000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: left;
    
  }
  .signup-card-header-sub{
    width: 100%;
    height: 42px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 21px; */
    letter-spacing: 0em;
    text-align: left;
  }
  .form-main-container{
    width:100%;
    height: 304px;

  
  }
  .form-box-item{
    width: 100%;
    height: 40px;
    /* padding: 8px 16px 8px 16px; */
    border-radius: 4px;
    border: 0.5px;
    border: 0.5px solid #DADCE0;
  }
  
  .login-link-box{
  width: 100%;
  height:48px;
  padding: 5px;
  border-radius: 4px;
  border: 0.5px;
  border: 0.5px solid #DADCE0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  }
  .submit-button , .otp-button {
   height:40px;
   font-size: 8px;
  }

  
}

@media (min-width:481px) and (max-width:768px){
  .free-org-admin-form .ant-form-item-control-input-content>label {
    font-size: 2.5vw;
  }
}

@media (min-width:769px) and (max-width:1024px){
  .free-org-admin-form .ant-form-item-control-input-content>label {
    font-size: 2.5vw;

  }
  .left-banner-main{
    height: 50vh;
  }
}