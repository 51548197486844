.free-org-admin-form {
  /* max-height: 70vh;
    overflow: scroll; */
}

.free-org-admin-form .ant-form-item-control-input-content>label {
  position: absolute;
  top: -1.5vh;
  left: 1.8vw;
  z-index: 1;
  background-color: white;
  padding: 0px 5px;
  color: #424242;
  font-size: 1vw;
  font-weight: 600;
}

.free-org-admin-form .ant-modal-content {
  border-radius: 10px;
  width: 38vw;
}

.free-org-admin-form .ant-input {
  /* height: 7vh; */
}

.free-org-admin-form .ant-btn {
  /* height: 7vh; */
  font-weight: 900;
}

.free-org-admin-form .ant-form-item {
  margin-bottom: 5vh;
}

.free-org-admin-form .ant-modal-body {
  padding: 0 24px;
}

.free-org-admin-form .tNc {
  font-size: 1.8vh;
  margin-bottom: 10px;
}

.free-org-admin-form .submit-button {
  /* font-size: 1.2vh; */
}

@media (min-width:300px) and (max-width:480px){
  .free-org-admin-form .ant-form-item-control-input-content>label {
    font-size: 3vw;
  }
  
}

@media (min-width:481px) and (max-width:768px){
  .free-org-admin-form .ant-form-item-control-input-content>label {
    font-size: 2.5vw;
  }
}

@media (min-width:769px) and (max-width:1024px){
  .free-org-admin-form .ant-form-item-control-input-content>label {
    font-size: 2.5vw;

  }
  .left-banner-main{
    height: 50vh;
  }
}